<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .options-form
      .label 产品类型:
      el-select(v-model="businessType" placeholder="请选择产品类型" value-key="id" style="width: 380px")
        el-option(label="知识视频" value="知识视频")
        el-option(label="会员" value="会员")
    .options-form(style="margin-bottom: 0px;" v-if="businessType !== ''")
      .label 产品:
      .params 
        div(v-for="(item, index) in products" style="margin-bottom: 28px;align-items:center;display:flex;")
          span(style="margin-right: 30px;") ID:
          el-input(v-model="products[index].id" placeholder="请输入ID" style="width: 120px;margin-right: 30px;" @change="(value) => {getProductPrice(value, index)}")
          span(style="margin-right: 30px;" v-if="item.price !== ''") 原价:
          span(style="margin-right: 30px;" v-if="item.price !== ''") ¥ {{item.price}}
          span(style="margin-right: 30px;" v-if="item.price !== ''") 推广价:
          el-input(v-model="products[index].promotePrice" v-if="item.price !== ''" placeholder="请输入推广价" style="width: 120px;margin-right: 10px;")
          span(style="margin-right: 30px;" v-if="item.price !== ''") 元
          i.el-icon-remove-outline(@click="products.splice(index, 1);" v-if="index !== 0" style="margin-right: 30px;")
          i.el-icon-circle-plus-outline(@click="products.push({id: '',promotePrice: '', price: ''});")
    .options-form
      .label 用户ID:
      el-input(type="textarea" :autosize="{ minRows: 4, maxRows: 4}" style="width: 380px;" placeholder="请输入内容" v-model="userIds")
    .options-form
      .label 总金额:
      .params(style="color: #ff724c;line-height: 40px") ¥ {{allPrice}}
    .options-form
      .label 推广渠道:
      el-select(v-model="belongPlatformId" placeholder="请选择推广渠道" style="width: 380px")
        el-option(v-for="item in belongPlatformList" :key="item.id" :label="item.name" :value="item.id")
    .options-form
      .label 推广方式:
      el-select(v-model="promoteType" placeholder="请选择推广方式" value-key="id" style="width: 380px")
        el-option(label="广告订单" value="广告订单")
        el-option(label="主播带货" value="主播带货")
    .options-form(v-if="promoteType === '主播带货'")
      .label 主播平台号:
      el-input(style="width: 380px;" placeholder="请输入主播平台号" v-model="platformUserId")
    .btns
      .action(@click="create") 新增
    .notice(v-if="tips")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        span(v-for="item in tips") · {{item}}
  .content-container(v-if="selectType === 2")
    .search-tab
      .search-account
        .label(style="margin-right: 20px;") 筛选：
        el-select(v-model="status" placeholder="请选择状态")
          el-option(label="全部" :value="-1")
          el-option(label="待审核" :value="1")
          el-option(label="已生效" :value="2")
          el-option(label="已拒绝" :value="3")
          el-option(label="部分退款" :value="4")
          el-option(label="已退款" :value="5")
      .search-code
        .label(style="margin-right: 20px;width: 48px;") 搜索：
        el-select(v-model="queryType" placeholder="请选择搜索类型" style="margin-right: 20px;width: 200px;")
          el-option(label="站外单号" value="1")
          el-option(label="产品Id" value="2")
          el-option(label="用户Id" value="3")
          el-option(label="主播平台号" value="4")
        el-input(v-model="queryContent" placeholder="请输入内容" style="width: 200px;")
        img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
    el-empty.el-empty(description="暂无数据" :image-size="200" v-if="records.length === 0")
    .list(v-for="(item, index) in records" v-if="records.length > 0")
      .options
        .label 站外单号:
        .params {{ item.orderId }}
      .options
        .label 产品类型:
        .params {{item.businessType}}
      .options
        .label 产品:
        .params
          .options(v-for="itm in item.productList")
            .label(style="margin: 0 40px 0 0;width: auto;" v-if="itm.productId") ID: {{itm.productId}}
            .label(style="margin: 0 40px 0 0;width: auto;" v-if="itm.originalProductPrice") 原价: {{itm.originalProductPrice}}
            .label(style="margin: 0 40px 0 0;width: auto;" v-if="itm.promoteProductPrice") 推广价: {{itm.promoteProductPrice}}
      .options
        .label 用户ID:
        .params {{ item.userIds }}
      .options
        .label 总金额:
        .params(style="color: #ff724c;") ¥ {{item.totalPrice}}
      .options
        .label 推广渠道:
        .params {{ item.belongPlatform }}
      .options
        .label 推广方式:
        .params {{item.promoteType}}
      .options(v-if="item.promoteType !== '广告订单'")
        .label 主播平台号:
        .params {{item.platformUserId}}
      .options
        .label 状态:
        .params 
          el-tag(type="success" v-if="item.status === 1" style="margin-right:5px;") 待审核
          el-tag(type="success" v-if="item.status === 2" style="margin-right:5px;") 已生效
          el-tag(type="success" v-if="item.status === 3" style="margin-right:5px;") 已拒绝
          el-tag(type="success" v-if="item.status === 4" style="margin-right:5px;") 部分退款
          el-tag(type="success" v-if="item.status === 5" style="margin-right:5px;") 已退款
      .options(v-if="item.status === 1 || item.status === 3")
        .label 拒绝原因:
        //- .params
          //- el-input(v-model="item.refuseReason" placeholder="请输入内容" style="width: 500px" :disabled="!hasMarketingAuth || item.status === 3")
        .params(v-if="item.status === 1")
          el-input(v-model="item.refuseReason" placeholder="请输入内容" style="width: 500px" :disabled="item.status !== 1 && hasMarketingAuth")
        .params(v-if="item.status === 3") {{item.refuseReason}}
      .options
        .label 创建人:
        .params {{item.createUserName}}
      .options
        .label 审批人:
        .params {{item.auditUserName}}
      .options
        .label 创建时间:
        .params {{item.createTime}}
      .btns
        el-popconfirm(confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认通过么" @confirm="change(item, 'pass')" v-if="item.status === 1")
          .action(slot="reference") 通过
        el-popconfirm(confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认拒绝么" @confirm="change(item, 'reject')" v-if="item.status === 1")
          .action(slot="reference") 拒绝
        .action(@click="$router.push(`/orderList?orderId=${item.orderId}&queryType=5&orderType=3`)" v-if="item.status !== 1 && item.status !== 3") 查看生成的订单
    el-pagination(
      v-if="records.length > 0"
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 3")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        div(v-for="item in isTips")
          p {{item.question}}
          span {{item.answer}}
</template>
<script>
import {
  qaList,
  outSiteList,
  getTips,
  outSiteReject,
  outSitePass,
  platforms,
  getProductPrice,
  createOutSiteOrder
} from '../../api/orderAccount'
import {currentLoginUser} from '@/api/project'

export default {
  data() {
    return {
      tabBarList: [
        {
          name: '手动建单',
          value: 1
        },
        {
          name: '站外单列表',
          value: 2
        },
        {
          name: '什么是“站外单"？',
          value: 3
        }
      ],
      selectType: 1,
      isTips: [],
      tips: [],
      page: 1,
      pageSize: 10,
      status: -1,
      queryType: '',
      queryContent: '',
      total: 0,
      belongPlatformList: [],
      records: [],
      businessType: '',
      userIds: '',
      promoteType: '',
      belongPlatformId: '',
      platformUserId: '',
      allPrice: 0,
      products: [
        {
          id: '',
          promotePrice: '',
          price: ''
        }
      ]
    }
  },
  props: {},
  mounted() {
    qaList({position: '站外单-什么是站外单'}).then(res => {
      this.isTips = res.data
    })
    getTips({position: '站外单-手动建单'}).then(res => {
      this.tips = res.code === 200 ? res.data : []
    })
    platforms().then(res => {
      this.belongPlatformList = res.data
    })
    currentLoginUser().then(res => {
      this.userId = res.data.userId
      this.hasManageAuth = res.data.hasManageAuth
      this.hasMarketingAuth = res.data.hasMarketingAuth
    })
    this.search()
  },
  watch: {
    products: {
      handler(val) {
        // eslint-disable-next-line no-debugger
        this.getAllPrice()
      },
      deep: true
    },
    status() {
      if (this.selectType === 2) this.currentChange(1)
    },
    userIds() {
      this.getAllPrice()
    },
    selectType() {
      if (this.selectType === 2) this.currentChange(1)
    }
  },
  methods: {
    searchBtnClick() {
      if (this.queryType === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.queryContent === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.currentChange(1)
    },
    isPhone(phone) {
      let regs = /^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/
      if (!regs.test(phone)) {
        return false
      }
      return true
    },
    getAllPrice() {
      let isPhoneNumberLength = 0
      this.userIds.split(/[,，]/).forEach(item => {
        if (this.isPhone(item)) isPhoneNumberLength = isPhoneNumberLength + 1
      })
      let allPrice = 0
      this.products.forEach(item => {
        allPrice = allPrice + (item.promotePrice !== '' ? Number(item.promotePrice) : 0)
      })
      this.allPrice = allPrice * isPhoneNumberLength
    },
    getProductPrice(id, index) {
      getProductPrice({productId: id, businessType: this.businessType}).then(res => {
        this.$set(this.products[index], 'price', res.data.price)
      })
    },
    create() {
      let message = ''
      if (this.businessType === '') message = '请选择产品类型'
      if (this.products.length === 0 && this.products[0].id === '') message = '请输入产品 ID'
      for(let i = 0; i < this.products.length; i ++) {
        if (this.products[i].promotePrice === '') {
          message = `清楚如产品 ID 为 ${this.products[i].id} 的推广价`
          break
        }
      }
      if (this.userIds === '') message = '请输入用户 ID'
      if (this.belongPlatformId === '') message = '请选择推广渠道'
      if (this.promoteType === '') message = '请选择推广方式'
      if (this.promoteType === '主播带货' && this.platformUserId === '') message = '请输入主播平台号'
      if (message) {
        this.$message.warning(message)
        return
      }
      const params = {
        businessType: this.businessType, //产品类型 知识视频；会员
        products: this.products, //产品id列表 会员、知识视频等
        userIds: this.userIds, //客户ids
        belongPlatformId: this.belongPlatformId,
        promoteType: this.promoteType
      }
      if (this.promoteType === '主播带货') {
        params.platformUserId = this.platformUserId
      }
      createOutSiteOrder(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.selectType = 2
          this.businessType = ''
          this.userIds = ''
          this.promoteType = ''
          this.belongPlatformId = ''
          this.platformUserId = ''
          this.products = [
            {
              id: '',
              promotePrice: '',
              price: ''
            }
          ]
          return
        }
        this.$message.error(res.data)
      })
    },
    search() {
      outSiteList({
        pageNum: this.page,
        status: this.status,
        pageSize: this.pageSize,
        queryType: this.queryType,
        queryContent: this.queryContent
      }).then(res => {
        this.total = res.data.total
        this.records = res.data.records
      })
    },
    change(item, type) {
      const params = {recordId: item.recordId}
      const fun = type === 'reject' ? outSiteReject : outSitePass
      if (type === 'reject') {
        params.refuseReason = item.refuseReason
        if (params.refuseReason === '') {
          this.$message.error('拒绝原因不能为空')
          return
        }
      }
      fun(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    currentChange(val) {
      this.page = val
      this.search()
    },
    // 下拉选change事件
    handleSelectChange(value, type) {
      this.page = 1
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.content-container {
  box-sizing: border-box;
  padding-top: 20px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.el-pagination {
  // position: absolute;
  // right: 20px;
  // bottom: 50px;
  margin: 30px 0;
}
.search-account,
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  padding: 0 20px;
}

.list {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  .options {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    text-align: center;
    margin-bottom: 15px;
    align-items: flex-start;

  }
  .params {
    width: 700px;
    text-align: left;
    word-wrap: break-word;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 6px;
    vertical-align: bottom;
    align-items: flex-end;
    margin-right: 20px;
    width: 130px;
    span {
      font-weight: 500;
      font-size: 20px;
    }
    .icon {
      font-size: 14px;
      margin-right: 2px;
    }
    .num {
      font-size: 16px;
    }
  }
  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}
.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  span {
    display: block;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 10px;
    color: #5ad3cd;
    font-weight: bold;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  margin-right: 30px;
}
.options-form {
  margin-bottom: 48px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  .label {
    width: 130px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 40px;
    text-align: left;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
.el-empty {
  margin-top: 140px;
}
</style>
<style>
.el-empty__description {
  text-align: center;
}
.el-empty__image {
  margin: 20px auto;
}
</style>
