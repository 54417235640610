<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .share-page
      .icon
      .share-page-content
        p 我的分享链接
        p
          span  https://www.quanqingkeji.com/#/home?promoter_id={{userId}}&promote_type=share
        .btns
          .action(@click="copy")
            span 复制
    .share-page
      .icon
      .share-page-content
        p 我的分享二维码
        .btns(v-if="!showShareCode")
          .action(@click="showShareCode = true")
            span 生成我的二维码
        p(v-if="showShareCode" style="margin-bottom: 0;") 二维码图片
        vue-qr(v-if="showShareCode && pcbaUrl" :text="pcbaUrl" :size="138" style="display: block; margin: 0 0 0 -19px;")
        vue-qr(ref="qrcode" v-if="showShareCode && pcbaUrl" :text="pcbaUrl" :size="138" style="display: block; margin: 0 0 0 -19px;position:fixed;top: 9999px")
        .btns(v-if="posterUrl === ''")
          .action(@click="getPicture")
            span 生成我的海报
        img(:src="posterUrl" style="width:200px;")
    .notice
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        span · 复制您的分享链接，或下载您的二维码图片或海报，分享给您的好友。好友访问全倾并注册下单后，您即能获得佣金。
        span · 具体佣金比例和生效规则，参见“什么是分享”页。
  .content-container(v-if="selectType === 2")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1;margin-top: 10px;")
        span 可以在各个产品页面，点击分享按钮，直接分享那个产品给他人。。
        .btns
          .bargainirg(@click="$router.push('/techVideo')")
            span 去知识视频页
          .bargainirg(@click="$router.push('/quanqingStandard')")
            span 去质检包页
  .content-container(v-if="selectType === 3" style="display: flex;flex-direction: row;")
    div(style="flex: 1; margin-right: 30px")
      .search-tab(style="justify-content: flex-end;margin-bottom:10px;")
        .search-code
          el-input(v-model="phoneNumber" placeholder="注册者手机号")
          img.search-icon(src="/img/search-icon.png" @click="searchClick")
      el-table(:data="phoneOrderInfo.records" style="width: 100%")
        el-table-column(prop="registerUserPhoneNumber" label="注册者手机号")
        el-table-column(prop="registerTime" label="注册时间")
      el-pagination(
        class="el-pagination"
        background
        :page-size="pagePhoneSize"
        :pager-count="11"
        layout="prev, pager, next"
        @current-change="currentPhoneChange"
        :total="phoneTotal"
        style="text-align: right;"
      )
    .common(style="width: 300px;height: 160px;")
      .options
        .name 有 {{phoneOrderInfo.registerTotal}} 人通过我的分享注册了
  .content-container(v-if="selectType === 4")
    .common
      .options
        .label {{orderInfo.total}}
        .name 被分享者下单数
      .options
        .label
          span.icon-price  ¥
          span {{orderInfo.orderTotalAmount}}
        .name 下单总金额

      .options
        .label
          span.icon-price  ¥
          span {{orderInfo.anticipativeTotalCommission}}
        .name 佣金总额
      .options
        .label
          span.icon-price  ¥
          span {{orderInfo.anticipativeEffectedTotalCommission}}
        .name 已生效佣金
          el-tooltip.item(effect="dark" content="订单超过可退款期限，用户没有退款且没有申请中的退款时，佣金即生效。具体可到个人中心页的我的收入栏查阅" placement="bottom")
            i.el-icon-question
    .search-tab
      .search-account
        span(style="width: 48px;display: inline-block;") 筛选：
        el-select(v-model="status" placeholder="请选择状态" @change="(value) => handleSelectChange(value, 'status')")
          el-option(label="全部" :value="-1")
          el-option(label="未生效" :value="0")
          el-option(label="已生效" :value="1")
      .search-code
        span(style="width: 48px;display: inline-block;") 搜索：
        el-select(v-model="queryType" placeholder="请选择搜索类型" style="margin-right: 20px;width: 200px;")
          el-option(label="下单人 ID" value="1")
          el-option(label="订单号" value="2")
        el-input(v-model="queryContent" placeholder="请输入内容" style="width: 200px;")
        img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
    el-table(:data="orderInfo.items" style="width: 100%;margin-top: 20px;margin-bottom:20px;")
      el-table-column(prop="orderUserId" label="下单人ID")
      el-table-column(prop="orderId" label="订单号")
      el-table-column(prop="orderAmount" label="订单金额")
        template(slot-scope="scope")
          span ¥ {{scope.row.orderAmount}}
      el-table-column(prop="anticipativeCommission" label="预期佣金")
        template(slot-scope="scope")
          span(style="margin-right: 10px;") ¥ {{scope.row.orderAmount}}
          el-tag(type="success" style="margin-right:5px;") {{scope.row.commissionIsEffected ? '已生效' : '未生效'}}
      el-table-column(prop="orderTime" label="下单时间")
    el-pagination(
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 5")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        div(v-for="item in isTips")
          p {{item.question}}
          span {{item.answer}}
</template>
<script>
import {qaList, orderRecords, registerList} from '../../api/orderAccount'
import {currentLoginUser} from '@/api/project'
import VueQr from 'vue-qr'

export default {
  data() {
    return {
      posterUrl: '',
      showShareCode: false,
      tabBarList: [
        {
          name: '我的分享码',
          value: 1
        },
        {
          name: '直接在产品页分享',
          value: 2
        },
        {
          name: '注册记录',
          value: 3
        },
        {
          name: '下单记录',
          value: 4
        },
        {
          name: '什么是“分享”？',
          value: 5
        }
      ],
      selectType: 1,
      isTips: [],
      promoteType: 'share',
      status: -1,
      queryType: '',
      phoneNumber: '',
      queryContent: '',
      pcbaUrl: '',
      page: 1,
      pageNum: 1,
      userId: '',
      pagePhoneSize: 10,
      pageSize: 10,
      orderInfo: {},
      phoneOrderInfo: {},
      phoneTotal: 0,
      total: 0
    }
  },
  components: {
    VueQr
  },
  props: {},
  watch: {
    status() {
      this.currentChange(1)
      this.currentPhoneChange(1)
    },
    // phoneNumber() {
    //   this.pageNum = 1
    //   this.searchPhone()
    // },
    queryContent() {
      this.page = 1
      this.search()
    }
  },
  mounted() {
    qaList({position: '分享-什么是分享'}).then(res => {
      this.isTips = res.data
    })
    currentLoginUser().then(res => {
      this.userId = res.data.userId
      this.pcbaUrl = `https://www.quanqingkeji.com/#/home?promoter_id=${this.userId}&promote_type=share`
      this.qrcode = `https://www.quanqingkeji.com/#/home?promoter_id=${this.userId}&promote_type=share`
    })
    this.search()
    this.searchPhone()
    this.currentChange(1)
    this.currentPhoneChange(1)
  },
  methods: {
    searchClick() {
      this.pageNum = 1
      this.searchPhone()
    },
    searchBtnClick() {
      if (this.queryType === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.queryContent === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.currentChange(1)
      this.currentPhoneChange(1)
    },
    createPoster(options) {
      const canvas = document.createElement('canvas')
      canvas.width = options.width || 500
      canvas.height = options.height || 600
      const ctx = canvas.getContext('2d')

      // 背景图
      const background = new Image()
      background.crossOrigin = 'Anonymous'
      background.src = options.background
      background.onload = () => {
        ctx.drawImage(background, 0, 0, canvas.width, canvas.height)
        // 图像
        const img = new Image()
        img.src = this.$refs.qrcode.imgUrl
        img.crossOrigin = 'Anonymous'
        img.onload = () => {
          ctx.drawImage(img, 130, 220, 50, 50)
          // 导出为DataURL
          const dataURL = canvas.toDataURL('image/png')
          const poster = new Image()
          poster.src = dataURL
          this.posterUrl = dataURL
          console.log('%c [ this.posterUrl ]-239', 'font-size:13px; background:pink; color:#bf2c9f;', this.posterUrl)
        }
      }
    },
    getPicture() {
      // 使用示例
      this.createPoster({
        background: 'http://81.70.36.99:8002/download/imgDownload/202307/0c20dbd001ee4e64b5ef30a36c2c6491.jpg',
        // text: 'Hello World',
        image: 'http://81.70.36.99:8002/download/imgDownload/202307/0c20dbd001ee4e64b5ef30a36c2c6491.jpg',
        width: 200,
        height: 300
      })
    },
    search() {
      orderRecords({
        promoteType: this.promoteType,
        status: this.status,
        queryType: this.queryType,
        queryContent: this.queryContent,
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.total = res.data.total
        this.orderInfo = res.data
      })
    },
    searchPhone() {
      registerList({
        promoteType: this.promoteType,
        phoneNumber: this.phoneNumber,
        pageNum: this.pageNum,
        pageSize: this.pagePhoneSize
      }).then(res => {
        this.phoneTotal = res.data.total
        this.phoneOrderInfo = res.data
      })
    },
    // 下拉选change事件
    handleSelectChange(value, type) {
      this.page = 1
      this.search()
    },
    copyTextToClipboard(text) {
      const input = document.createElement('input')
      input.value = text
      document.body.appendChild(input)
      input.select() // 选择输入框的内容
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
    },
    copy() {
      this.copyTextToClipboard(`https://www.quanqingkeji.com/#/home?promoter_id=${this.userId}&promote_type=share`)
    },
    currentPhoneChange(val) {
      this.pageNum = val
      this.searchPhone()
    },
    currentChange(val) {
      this.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.search-account,
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}

.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}

.content-container {
  box-sizing: border-box;
  padding-top: 20px;
}

.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;

  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }

  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}

.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  span {
    display: block;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 10px;
    color: #5ad3cd;
    font-weight: bold;
  }
}

.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  margin-right: 30px;
}

.common {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;

  .options {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }

  .label {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 6px;
    vertical-align: bottom;
    align-items: flex-end;

    span {
      font-weight: 500;
      font-size: 20px;
    }

    .icon-price {
      font-size: 14px;
      margin-right: 2px;
    }

    .num {
      font-size: 16px;
    }
  }

  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }

  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }

  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}

.btns {
  display: flex;
  justify-content: flex-start;
}

.content-container {
  .bargainirg {
    height: 56px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    text-align: center;
    cursor: pointer;

    span {
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      font-size: 22px !important;
      color: #ffffff !important;
      text-align: center;
      margin: 0;
    }
  }
}

.share-page {
  margin-bottom: 20px;
  border-bottom: 1px dashed #dcdfe6;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.icon {
  width: 8px;
  height: 8px;
  margin: 10px 30px 0 30px;
  border-radius: 50%;
  background-color: #5ad3cd;
}

.share-page-content {
  flex: 1;

  p {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    color: rgb(51, 51, 51);
    line-height: 26px;
    margin-bottom: 30px;
  }
}

.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 40px;
  padding: 0 20px;
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
</style>
