<template lang="pug">
.subject
  layoutContainer
    .video
      video(id="player-container-id" width="1200" height="675" preload="auto" playsinline webkit-playsinline)
      p(class="notice" v-if="!supportFreeWatch && !detail.buyFlag && !detail.hasPublisher") 购买后可观看完整视频
    .action(v-if="detail.hasPublisher || detail.hasManageOperator")
      .action-li
        .tab-actions
          .option 状态：
          .btns
            .tag(v-if="detail.publishStatus === 1") 待审核
            .tag(v-if="detail.publishStatus === 2") 未通过
            .tag(v-if="detail.publishStatus === 3") 已上架
            .tag(v-if="detail.publishStatus === 4") 已下架
        .tab-actions
          .option 审核记录：
          .btns(style="margin-bottom: 0;")
            .check(@click="showAuditRecord = true") 查看
      .action-li
        .tab-actions
          .option 销量：
          .btns
            span {{ detail.saleNum }}
        .tab-actions
          .option 销售额：
          .btns 
            span(style="color: #FF724C;") ¥{{ detail.saleAmount }}
        .tab-actions
          .option 销售记录：
          .btns(style="margin-bottom: 0;")
            .check(@click="checkSale") 查看
            //- el-button(type="primary" round @click="showAuditRecord = true") 查看
      .action-li
        .btns(style="margin-bottom: 10px;" v-if="detail.publishStatus !== 1")
          //- v-if="detail.publishStatus !== 2" 
          .tag(@click="changType = 'uploadVideo'; showPublishDialog = true") 重新上传
          .tag(@click="changType = 'change'; showPublishDialog = true") 修改
          .del(@click="popconfirm('down')" v-if="detail.publishStatus === 3") 下架
        .btns(v-if="detail.publishStatus !== 1")
          .tag(@click="changType = detail.hasPreviewPicUrl ? 'uploadImg' : 'addImage'; showPublishDialog = true" v-if="detail.publishStatus !== 2") {{ detail.hasPreviewPicUrl ? '更新小图' : '上传小图' }}
          .check(@click="popconfirm('update')" v-if="detail.publishStatus === 4 && detail.publishStatus !== 2") 上架
          .del(@click="popconfirm('del')") 删除
    .detail
      .content
        .comp-info-basic-header
          .comp-info-basic-info 
            div(style="display: flex;align-items: flex-start;")
              span.detail-name {{detail.name}}
              span.desc 7天无理由退款
            span(style="font-size:16px;color: #999999;") {{detail.createTime}}
          .purchase-btn(v-if="detail.price")
            .container
              p.discount-price(v-if="!detail.buyFlag") 
                span.discount-price-decoration(v-if="detail.hasDiscount") ￥{{detail.price}}
                span.price ￥{{detail.hasDiscount ? detail.discountPrice : detail.price}}
              p.btn(@click="buy" v-if="!detail.buyFlag && !detail.hasPublisher && detail.publishStatus === 3") 购买
        .comp-share-tab
          .action-btns
            .share-btn(@click="collectApi")
              img(:src="detail.hasCollection ? '/img/已收藏.png' : '/img/收藏.png'")
              span {{ detail.collectionNum }}
            .follow-btn(@click="shareCourse" v-if="detail.publishStatus !== 4 && detail.publishStatus !== 2 && detail.publishStatus !== 1")
              img(src="/img/share.png")
          .comp-pay-tab(v-if="(detail.hasSupportBargain || detail.hasSupportTogetherBuy) && detail.publishStatus !== 4 && detail.publishStatus !== 2 && detail.publishStatus !== 1")
            .btn-pay-tab(@click="$router.push({path: `/bargainPage?courseId=${detail.id}&courseName=${detail.name}&bargainTogetherType=1&hasSupportBargain=${detail.hasSupportBargain}&hasSupportTogetherBuy=${detail.hasSupportTogetherBuy}`})" v-if="detail.hasSupportBargain")
              img.icon(src="/img/砍价.png")
              span 砍价
            .btn-pay-tab(@click="$router.push({path: `/bargainPage?courseId=${detail.id}&courseName=${detail.name}&bargainTogetherType=2&hasSupportBargain=${detail.hasSupportBargain}&hasSupportTogetherBuy=${detail.hasSupportTogetherBuy}`})" v-if="detail.hasSupportTogetherBuy")
              img.icon(src="/img/拼团.png")
              span 拼团
            .swiper-container(v-if="swiperList.length > 0")
              .swiper-wrapper
                .swiper-slide(v-for="(item, key) in swiperList" :key="key")
                  p.title {{ item.content }}
                  .swiper-action(@click="bargainJoin(item.type, item.requestId)")
                    span {{ item.type === 1 ? '帮砍' : '拼团' }}
                    img.swiper-action-icon(src="/img/swiperMore.png")
            .total(@click="$router.push({path: `/bargainPage?courseId=${detail.id}&courseName=${detail.name}&hasSupportBargain=${detail.hasSupportBargain}&hasSupportTogetherBuy=${detail.hasSupportTogetherBuy}&bargainTogetherType=-1`})" v-if="swiperList.length > 0") 全部({{swiperList.length}})
    .detail
      .content
        .comp-info-basic-dec
          .comp-info-basic 
            p.point
            p.sub-title 介绍：
          .curse-dec {{detail.introduction}}
        .comp-info-basic-dec
          .comp-info-basic 
            p.point
            p.sub-title 内容：
          .curse-content(v-html="detail.content")
    .author
      .title 作者
      div(style="flex: 1;")
        .info
          img.avatar(:src="detail.employeePhotoUrl" @click="checkClass")
          .name(@click="checkClass") {{detail.employeeName}}
          el-button(type="primary" round @click="followed") {{ detail.hasFollowed ? '取消关注' : '关注' }}
          el-button(type="primary" round @click="checkAvatar") 查看个人页
          el-button(type="primary" round @click="editAvatarInfo" v-if="detail.hasPublisher || detail.hasManageOperator") {{!isEditAvatar ? '编辑简介' : '确定'}}
        .employee-introduction_visibility(v-if="!isEditAvatar") {{detail.employeeIntroduction}}
        el-input.employee-introduction_visibility(type="textarea" v-if="isEditAvatar" v-model="employeeIntroduction")
    .project-comment
      .project-comment-title
        p.point
        p.name 评价
      el-divider.el-divider
      .project-comment-content
        .project-comment-content-item(v-for="(item, index) in commentList" :key="`${index}projectComment`")
          .project-comment-content-item-basic
            .name
              .project-comment-content-item-basic-name {{item.userName}}
            .project-comment-content-item-basic-score(v-for="i in item.score" :key="`${i}111`")
              img(src="../../assets/start-comment.png" alt="")
            .project-comment-content-item-basic-score(v-for="i in (5 - item.score)" :key="`${i}222`")
            .project-comment-content-item-basic-time {{item.createTime}}
          .project-comment-content-item-basic-content
            span {{item.evaluation}}
            img(src="../../assets/guanbi2x.png" @click="handleDelete(item.id)" v-if="item.hasDeleteAuth")
        .comment-btn(@click="handleComment" v-if="detail.buyFlag") 写评价
    comment-dialog(
      :classId="detail.id",
      :showCommentDialog.sync="showDialog",
      @update="update"
    )
    el-dialog(:visible.sync="shareDialog" width="320px" :show-close="true" @close="shareDialog = false" title="分享")
      p.share-text 微信“扫一扫”，点击分享
      vue-qr(:text="pcbaUrl" :size="138" style="display: block; margin: auto")
    publishDialog(
      :showPublish.sync="showPublishDialog", 
      :courseId="detail.id"
      :type="changType"
      @publishSuccess="publishSuccess"
      :occupationList="occupationList"
    )
    auditRecord(
      :showPublish.sync="showAuditRecord", 
      :courseId="detail.id"
    )
</template>

<script>
import {getCourseDetail, getCommentList, deleteComment, pay} from '@/api/subject'
import {
  followed,
  cancelFollowed,
  updateAuthorIntroduction,
  publishCourse,
  simpleList,
  currentLoginUser,
  bargainJoin
} from '@/api/project'
import {mapState} from 'vuex'

import {collectApi, cancelCollectApi} from '@/api/product'
import commentDialog from '@/components/subject/commentDialog.vue'
import {formatTimeFilter} from '../../utils/index'
import {getEmployeeOccupationListApi} from '@/api/index'
import {checkAuth} from '@/api/product'
import VueQr from 'vue-qr'
import publishDialog from '@/components/techVideo/publishDialog.vue'
import auditRecord from '@/components/techVideo/auditRecord.vue'
import Swiper from 'swiper'

export default {
  name: 'subjectDetail',
  data() {
    return {
      detail: '',
      commentList: [],
      supportFreeWatch: true,
      showDialog: false,
      showPublishDialog: false,
      winList: [],
      isOperator: false,
      showAuditRecord: false,
      total: 0,
      changType: '',
      isEditAvatar: false,
      occupationList: [],
      shareDialog: false,
      employeeIntroduction: '',
      pcbaUrl: '',
      userId: '',
      swiperList: []
    }
  },
  computed: {
    ...mapState(['UserInfo'])
  },
  components: {
    publishDialog,
    commentDialog,
    auditRecord,
    VueQr
  },
  created() {
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
    currentLoginUser().then(res => {
      this.userId = res.data.userId
      this.pcbaUrl = `${window.location.href}&promoter_id=${this.userId}&promote_type=share`
    })
    this.getEmployeeOccupationListApi()
    this.getCourseDetail()
    const {id} = this.$route.query
    simpleList({productId: id, businessType: '知识视频'}).then(res => {
      this.swiperList = res.data.records
      this.$nextTick(() => {
        setTimeout(() => {
          this.mySwiper()
        }, 200)
      })
    })
  },
  beforeRouteLeave(to, from, next) {
    this.player.dispose()
    next()
  },
  methods: {
    shareCourse() {
      if (!this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      this.shareDialog = true
    },
    bargainJoin(type, requestId) {
      if (!this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      const params = {
        requestId: requestId
      }
      // item.type === 1 ? '砍价' : '拼团'
      if (type === 1) {
        bargainJoin(params).then(res => {
          if (res.requestId) {
            this.$message.success(`帮砍成功`)
          } else {
            this.$message.error(res.data)
          }
        })
      } else {
        togetherJoin(params).then(res => {
          if (res.code === 200) {
            this.$message.success(`拼团成功`)
          } else {
            this.$message.error(res.data)
          }
        })
      }
    },
    checkSale() {
      const {id} = this.$route.query
      this.$router.push({path: `/saleRecord?courseId=${id}&courseName=${this.detail.name}`})
    },
    popconfirm(type) {
      this.$confirm(
        `${
          type === 'down'
            ? '确定申请下架？'
            : type === 'update'
            ? '确定申请上架？'
            : type === 'del'
            ? '确定申请删除？'
            : ''
        }`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        if (type === 'down') this.changeStatus('下架')
        if (type === 'update') this.changeStatus('上架')
        if (type === 'del') this.changeStatus('删除')
      })
    },
    mySwiper() {
      this.swiper = new Swiper('.swiper-container', {
        pagination: {
          clickable: true,
          el: '.swiper-pagination'
        },
        loop: true,
        direction: 'vertical',
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        }
      })
    },
    getEmployeeOccupationListApi() {
      getEmployeeOccupationListApi().then(res => {
        this.occupationList = res.data
      })
    },
    changeStatus(status) {
      const params = {
        action: status,
        courseId: this.detail.id
      }
      publishCourse(params).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        } else {
          let message =
            status === '上架' ? '已提交上架申请！' : status === '下架' ? '已提交下架申请！' : '已提交删除申请'
          this.$message.success(message)
          if (status === '上架' || status === '下架') {
            this.getCourseDetail()
          }
        }
      })
    },
    publishSuccess() {
      this.showPublishDialog = false
      this.getCourseDetail()
    },
    collectApi() {
      const fn = this.detail.hasCollection ? cancelCollectApi : collectApi
      fn({
        objectId: this.detail.id, //收藏品id
        objectType: '知识视频'
      }).then((res) => {
        if (res.code !== 200) return
        this.$message.success(this.detail.hasCollection ? '取消收藏成功' : '收藏成功')
        const {id} = this.$route.query
        getCourseDetail({id}).then(res => {
          this.$set(this.detail, 'hasCollection', res.data.hasCollection)
          this.$set(this.detail, 'collectionNum', res.data.collectionNum)
        })
      })
    },
    buy() {
      const {id} = this.$route.query
      pay({id}).then(res => {
        if (res.code === 200009) {
          this.$router.push({
            path: '/login'
          })
        }
        const {code, data} = res
        if (code === 200 && data) {
          this.$router.push({
            path: '/pay',
            query: {
              courseId: id,
              orderId: res.data
            }
          })
        } else {
          this.$dialog.toast(res.msg)
        }
      })
    },
    getCourseDetail() {
      const {id} = this.$route.query
      getCourseDetail({
        id
      }).then(res => {
        this.detail = res.data
        this.employeeIntroduction = res.data.employeeIntroduction
        this.init()
        // this.player.src(this.detail.videoUrl) // url 播放地址
        console.log('this.detail.videoUrl: ', this.detail.videoUrl)
        this.supportFreeWatch = this.detail.supportFreeWatch
        this.$nextTick(() => {
          this.player = window.TCPlayer('player-container-id', {
            fileID: this.detail.fileId,
            appID: this.detail.appId,
            psign: this.detail.psign,
            autoplay: false,
            plugins: {
              ProgressMarker: true,
              ContextMenu: {
                statistic: true,
                levelSwitch: {
                  open: true,
                  switchingText: 'Start switching',
                  switchedText: 'Switch success',
                  switchErrorText: 'Switch fail'
                }
              }
            }
          }) // player-container-id 为播放器容器 ID，必须与 html 中一致
          this.player.one('playing', () => {
            var tipNode = document.createElement('div')
            if (!this.detail.buyFlag) {
              if (this.detail.freeWatchSeconds < 60) {
                tipNode.innerHTML = `可试看${this.detail.freeWatchSeconds}秒，购买后可观看完整视频`
              } else {
                tipNode.innerHTML = `可试看 ${Math.floor(this.detail.freeWatchSeconds / 60)}分${
                  Math.ceil(this.detail.freeWatchSeconds % 60) > 0
                    ? `${Math.ceil(this.detail.freeWatchSeconds % 60)}秒`
                    : '钟'
                }，购买后可观看完整视频`
              }
              tipNode.className = 'tip-node'

              var tipIconClose = document.createElement('i')
              tipIconClose.className = 'tip-icon-close'
              tipIconClose.onclick = function() {
                tipNode.remove()
              }

              tipNode.appendChild(tipIconClose)
              this.player.el_.appendChild(tipNode)
            }
          })

          this.player.on('ended', () => {
            var pageNode = document.createElement('div')
            pageNode.className = 'page-node'

            pageNode.innerHTML = `<div class="page-content">
              <p>试看结束，购买后可观看完整视频</p>
              <button id="replay-btn">重新试看</button>
            </div>
          `
            this.player.el_.appendChild(pageNode)

            document.querySelector('#replay-btn').onclick = () => {
              this.player.play()
              pageNode.remove()
            }
          })
        })
      })
    },
    init() {
      const params = {
        courseId: this.detail.id
      }
      getCommentList(params).then(res => {
        const data = res.data || []
        data.forEach(item => {
          item.createTime = item.createTime ? this.formatDate(item.createTime) : '2022-05-28'
        })
        this.commentList = data
      })
    },
    handleComment() {
      this.showDialog = true
    },
    handleDelete(id) {
      this.$confirm('确认删除？')
        .then(() => {
          deleteComment(id).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.init()
            }
          })
        })
        .catch(() => {})
    },
    followed() {
      if (this.detail.hasFollowed) {
        cancelFollowed({
          objectId: this.detail.employeeId || 111,
          objectType: '知识视频'
        }).then(res => {
          if (res.code !== 200) return
          this.$message.success('取消关注成功')
          this.$set(this.detail, 'hasFollowed', false)
        })
        return
      }
      followed({
        objectId: this.detail.employeeId || 111,
        objectType: '知识视频'
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('关注成功')
          this.$set(this.detail, 'hasFollowed', true)
        } else if (res.code === 100003) {
          this.$message.success('已关注')
          this.$set(this.detail, 'hasFollowed', true)
        }
      })
    },
    editAvatarInfo() {
      if (!this.isEditAvatar) {
        this.isEditAvatar = true
        return
      }
      if (this.employeeIntroduction.length <= 0) {
        this.$message.error('简介不可为空！')
        return
      }
      if (this.employeeIntroduction.length >= 200) {
        this.$message.error('内容字数不可超过200！')
        return
      }
      updateAuthorIntroduction({
        introduction: this.employeeIntroduction,
        employeeId: this.detail.employeeId || '111'
      }).then(() => {
        this.$set(this.detail, 'employeeIntroduction', this.employeeIntroduction)
        this.$message.success('更新成功')
        this.isEditAvatar = false
      })
    },
    checkClass() {
      this.$router.push({
        path: '/techVideo',
        query: {
          employeeName: this.detail.employeeName || '222',
          employeePhotoUrl: this.detail.employeePhotoUrl,
          employeeId: this.detail.employeeId || '111'
        }
      })
    },
    checkAvatar() {
      this.$router.push({
        path: `/personal/detail/${this.detail.employeeId}`
      })
    },
    update() {
      this.init()
    },
    formatDate(time) {
      return formatTimeFilter(time, 'MM-DD HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;
.tag {
  min-width: 80px;
  height: 29px;
  border-radius: 28px 28px 28px 28px;
  border: 2px solid #5ad3cd;
  line-height: 25px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 18px;
  color: #5ad3cd;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  padding: 0 22px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
.del {
  min-width: 80px;
  height: 29px;
  border-radius: 28px 28px 28px 28px;
  border: 2px solid #ff5f56;
  line-height: 25px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 18px;
  color: #ff5f56;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  padding: 0 22px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
.check {
  min-width: 86px;
  height: 29px;
  background: #5ad3cd;
  border: 2px solid #5ad3cd;
  line-height: 25px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  padding: 0 22px;
  align-items: center;
  justify-content: center;
  border-radius: 28px 28px 28px 28px;
  margin-right: 10px;
  cursor: pointer;
}
.swiper-container {
  width: 310px;
  height: 45px;
  margin-right: 22px;
  overflow: hidden;
  p {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 18px;
    color: #acacac;
    line-height: 21px;
    text-align: left;
    margin-right: 24px;
  }
  .swiper-slide {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .swiper-action {
    width: 88px;
    height: 29px;
    background: #5ad3cd;
    border-radius: 28px 28px 28px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    span {
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      line-height: 21px;
      margin-right: 7px;
    }
    .swiper-action-icon {
      width: 16px;
    }
  }
}
.video {
  width: 1200px;
  overflow: hidden;
  height: 675px;
  background: #252525;
  position: relative;
  video {
    width: 100%;
  }
  .notice {
    position: absolute;
    left: 20px;
    bottom: 20px;
    color: #888888;
  }
}
.point {
  width: 12px;
  height: 12px;
  background: #5ad3cd;
  border-radius: 50%;
  margin-right: 10px;
}
.detail {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  .content {
    width: 100%;
  }
  .comp-info-basic-header {
    margin: 0 0 30px 0;
    box-sizing: content-box;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-content: center;
    line-height: 53px;
    border-bottom: 1px solid #eeeeee;
    .detail-name {
      // height: 37px;
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 37px;
      margin-right: 30px;
    }
    .desc {
      text-align: center;
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 37px;
      margin-right: 6px;
      word-break: keep-all;
    }
    .underline {
      text-decoration: underline;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5ad3cd;
      line-height: 28px;
      word-break: keep-all;
      cursor: pointer;
    }
    .purchase-btn {
      .container {
        display: flex;
        align-items: center;
        .price {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          font-size: 40px;
          color: #ff724c;
          margin-left: 30px;
        }
        .discount-price {
          display: inline-block;
          margin-right: 16px;
          display: flex;
          align-items: center;
        }
        .discount-price-decoration {
          text-decoration: line-through;
          font-weight: 400;
          font-size: 22px;
          color: #acacac;
        }
        .btn {
          display: inline-block;
          width: 130px;
          height: 53px;
          background: #ff724c;
          border-radius: 27px;
          text-align: center;
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .comp-share-tab {
    box-sizing: content-box;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-content: center;
    line-height: 53px;
    .action-btns {
      display: flex;
      align-items: center;
      flex-direction: row;
      .share-btn,
      .follow-btn {
        cursor: pointer;
        display: flex;
        margin-right: 45px;
        align-items: center;
        flex-direction: row;
        img {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
  .comp-pay-tab {
    box-sizing: content-box;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    height: 45px;
    box-sizing: border-box;
    background: #f6f6f8;
    border-radius: 12px 12px 12px 12px;
    padding: 0 16px;
    align-items: center;
    .btn-pay-tab {
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #ff724c;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 16px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: 24px;
        margin: 0 10px 0 12px;
      }
      span {
        font-weight: 600;
        font-size: 16px;
        color: #ff724c;
        display: inline-block;
        margin-right: 12px;
        height: 16px;
        line-height: 16px;
      }
    }
    .total {
      font-weight: 500;
      font-size: 18px;
      color: #5ad3cd;
      line-height: 18px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  .comp-info-basic-dec {
    margin: 0 0 10px 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #333333;
    font-size: 26px;

    .comp-info-basic {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
    }
    .sub-title {
      font-weight: bold;
    }
    .point {
      width: 12px;
      height: 12px;
      background: #5ad3cd;
      border-radius: 50%;
      margin-right: 10px;
    }
    .curse-dec {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-bottom: 30px;
    }
    .curse-content {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
  .comp-info-basic-header {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    .comp-info-basic {
      color: #999999;
      margin-right: 10px;
      white-space: nowrap;
    }
    .comp-info-basic-info {
      color: #333333;
      max-width: 700px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .detail {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5ad3cd;
        line-height: 25px;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .comp-info-basic-detail {
      margin-left: 10px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5ad3cd;
    }
  }
}
.action {
  padding: 18px 30px 18px 30px;
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  .action-li {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    .tab-actions {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      align-items: center;
    }
    .option {
      line-height: 32px;
      width: 100px;
    }
    .btns {
      display: flex;
      justify-content: flex-start;
      // margin-bottom: 10px;
    }
  }
}
.author {
  padding: 18px 30px 18px 0;
  position: relative;
  background-color: #fff;
  display: flex;
  .title {
    width: 90px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #5ad3cd;
    border-radius: 0px 100px 100px 0px;
    display: inline-block;
    margin-top: 2px;
    margin-right: 30px;
    color: #fff;
  }
  .info {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    .avatar {
      width: 54px;
      height: 54px;
      border-radius: 70px;
      vertical-align: top;
      margin-right: 8px;
      cursor: pointer;
    }
    .name {
      display: inline-block;
      vertical-align: top;
      margin-right: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4bbbb6;
      line-height: 54px;
      cursor: pointer;
    }
  }
  .employee-introduction_visibility {
    display: block;
    max-width: 897px;
  }
}
.project-comment {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 30px 54px 30px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;

  .project-comment-title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    display: flex;
    align-items: center;
  }
  .el-divider {
    height: 1px;
    margin: 30px 0 40px 0;
  }
  .project-comment-content {
    .name {
      vertical-align: top;
      display: inline-block;
      width: 200px;
    }
    .project-comment-content-item-basic {
      position: relative;
      margin-bottom: 28px;
      .project-comment-content-item-basic-name {
        vertical-align: top;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 20px;
      }
      .project-comment-content-item-basic-objectName {
        vertical-align: top;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-left: -16px;
      }
      .project-comment-content-item-basic-score {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 2px 7px 3px 0;
        img {
          width: 20px;
          height: 20px;
          display: inline-block;
        }
      }
      .project-comment-content-item-basic-time {
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        vertical-align: top;
        margin-left: 30px;
      }
    }
    .project-comment-content-item-basic-content {
      margin-bottom: 50px;
      span {
        display: inline-block;
        width: calc(100% - 64px);
        vertical-align: middle;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 2px;
        margin-left: 42px;
        cursor: pointer;
      }
    }
    .comment-btn {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      padding: 4px 0;
      text-align: center;
      background: #ffaa30;
      border-radius: 14px;
      width: 200px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
.qr-code {
  width: 138px;
  height: 138px;
  margin: 18px auto 20px;
  display: block;
}
.share-text {
  margin: 0 auto;
  text-align: center;
}
</style>
<style>
.tcplayer {
  margin: 0 auto;
}

.tip-node {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5em;
  left: 1em;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 5px 10px;
}

.tip-icon-close {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: 10px;
  background-image: url(https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/icon-close.png);
  background-size: cover;
  cursor: pointer;
}

.page-node {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content {
  text-align: center;
}

.page-content a {
  display: flex;
  width: 120px;
  height: 28px;
  border-radius: 14px;
  color: #663d00;
  background-image: linear-gradient(90deg, #ffdf89 0, #f2ca5b 100%);
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0 auto;
}

.page-content #replay-btn::before {
  content: '';
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background: url(https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/icon-replay.png);
  background-size: contain;
}

.page-content #replay-btn {
  margin: 10px auto 0;
}
</style>
