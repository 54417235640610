<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .options-form
      .label 短信模板:
      el-select(v-model="message" placeholder="请选择产品类型" value-key="id" style="width: 380px")
        el-option(v-for="item in messageList" :key="item.id" :label="item.name" :value="item")
    .options-form(v-if="message.content")
      .label 模版内容:
      .params {{message.content}}
    .options-form(style="margin-bottom: 0;" v-if="message.paramSize !== 0 && message.paramSize !== undefined")
      .label 参数内容:
      .project-id-el-input
        div(v-for="(item, index) in templateParams" style="margin-bottom: 48px;")
          span(style="margin-right: 30px;") 参数{{index + 1}}:
          el-input(v-model="templateParams[index]" placeholder="请输入参数" style="width: 300px;")
          //- i.el-icon-remove-outline(@click="templateParams.splice(index, 1);" v-if="index !== 0")
          //- i.el-icon-circle-plus-outline(@click="templateParams.push('');")
    .options-form
      .label 发送时间:
      el-select(v-model="sendTimeType" placeholder="请选择发送时间" value-key="id" style="width: 380px")
        el-option(key="立即发送" label="立即发送" value="立即发送")
        el-option(key="定时发送" label="定时发送" value="定时发送")
    .options-form(v-if="sendTimeType === '定时发送'")
      .label 定时设置:
      el-date-picker(v-model="startSendTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:SS")
    .options-form
      .label 接收手机号:
      .project-id-el-input
        el-input(type="textarea" :autosize="{ minRows: 4, maxRows: 4}" style="width: 380px;" placeholder="请输入接收手机号" v-model="receivePhoneNumbers")
    .btns
      .action(@click="create") {{id ? '修改' : '新增'}}
    .notice(v-if="tips")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        span(v-for="item in tips") · {{item}}
  .content-container(v-if="selectType === 2")
    .search-tab
      .search-account(style="margin-bottom: 30px;")
        span(style="margin-right: 30px;") 状态:
        el-select(v-model="status" placeholder="请选择状态")
          el-option(label="全部" :value="-1")
          el-option(label="待发送" :value="1")
          el-option(label="发送中" :value="2")
          el-option(label="已停止" :value="3")
          el-option(label="已完成" :value="4")
    el-empty.el-empty(description="暂无数据" :image-size="200" v-if="records.length === 0")
    .list(v-for="item in records" v-if="records.length > 0")
      .options
        .label 群发单ID:
        .params {{ item.id }}
      .options
        .label 短信模板:
        .params {{item.templateName}}
      .options
        .label 模板内容:
        .params {{ item.templateContent }}
      .options
        .label 参数内容:
        .params 
          div(v-for="(itm,idx) in item.templateParams" style="margin: 0 12px 12px 0")
            span(style="margin-right: 30px;") 参数{{idx + 1}}:
            span {{itm}}
      .options
        .label 发送时间:
        .params {{ item.sendTimeType }}
      .options(v-if="item.sendTimeType === '定时发送'")
        .label 定时设置:
        .params {{ item.startSendTime }}
      .options
        .label 接收手机号:
        .params {{ item.receivePhoneNumbers }}
      .options
        .label 状态:
        .params
          el-tag(type="success" v-if="item.status === 1" style="margin-right:5px;") 待发送
          el-tag(type="success" v-if="item.status === 2" style="margin-right:5px;") 发送中
          el-tag(type="success" v-if="item.status === 3" style="margin-right:5px;") 已停止
          el-tag(type="success" v-if="item.status === 4" style="margin-right:5px;") 已完成
      .options
        .label 发送数量:
        .params 需发送：{{item.shouldSendNum}}  /  已发送：{{item.alreadySendNum}}  /  发送失败： {{item.failSendNum}}
      .options
        .label 创建人:
        .params {{ item.createUserName }}
      .options
        .label 修改人:
        .params {{item.modifyUserName}}
      .options
        .label 创建时间:
        .params {{ item.createTime }}
      .btns
        .action(@click="change(item)") 修改
        .action(@click="messageAction('del', item.id)") 删除
        .action(@click="messageAction('stop', item.id)") 停止
        .action(@click="messageAction('keep', item.id)") 继续
    el-pagination(
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 3")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        div(v-for="item in isTips")
          p {{item.question}}
          span {{item.answer}}
</template>
<script>
import {
  qaList,
  groupMessage,
  getTips,
  saveGroupMessage,
  groupMessageList,
  removeGroupMessage,
  startGroupMessage,
  stopGroupMessage
} from '../../api/orderAccount'
export default {
  data() {
    return {
      tabBarList: [
        {
          name: '新增群发',
          value: 1
        },
        {
          name: '群发列表',
          value: 2
        },
        {
          name: '什么是“短信"？',
          value: 3
        }
      ],
      id: '',
      pageSize: 10,
      total: 0,
      page: 1,
      selectType: 1,
      isTips: [],
      templateParams: [''],
      messageList: [],
      message: {},
      sendTimeType: '',
      receivePhoneNumbers: '',
      tips: [],
      startSendTime: '',
      status: -1,
      records: []
    }
  },
  props: {},
  mounted() {
    qaList({position: '短信-什么是短信'}).then(res => {
      this.isTips = res.data
    })
    groupMessage().then(res => {
      this.messageList = res.data
    })
    getTips({position: '短信-新增群发'}).then(res => {
      this.tips = res.code === 200 ? res.data : []
    })
    this.search()
  },
  watch: {
    status() {
      this.currentChange(1)
    },
    selectType() {
      if (this.selectType === 2) {
        this.currentChange(1)
      }
    },
    message(val) {
      const templateParams = []
      for (let index = 0; index < this.message.paramSize; index++) {
        templateParams.push('')
      }
      this.templateParams = templateParams
    }
  },
  methods: {
    messageAction(type, id) {
      const fun = type === 'keep' ? startGroupMessage : type === 'stop' ? stopGroupMessage : removeGroupMessage
      fun({id}).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    search() {
      groupMessageList({
        status: this.status,
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.total = res.data.total
        this.records = res.data.records
      })
    },
    currentChange(val) {
      this.page = val
      this.search()
    },
    change(info) {
      this.id = info.id
      this.message = {
        id: info.templateId,
        templateName: info.templateName,
        content: info.templateContent
      }
      this.templateParams = info.templateParams
      this.sendTimeType = info.sendTimeType
      this.startSendTime = info.startSendTime
      this.receivePhoneNumbers = info.receivePhoneNumbers
      this.selectType = 1
    },
    create() {
      if (!this.message.id) {
        this.$message.warning('请选择短信模板')
        return
      }
      if (this.templateParams.length === 1 && this.templateParams[0] === '') {
        this.$message.warning('请输入请输入参数')
        return
      }
      let message = ''
      for(let i = 0; i < this.templateParams.length; i ++) {
        if (this.templateParams[i].length > 50) {
          message = `每个参数最多50字`
          break
        }
      }
      if (message) {
        this.$message.warning(message)
        return
      }
      if (this.sendTimeType === '') {
        this.$message.warning('请选择发送时间')
        return
      }
      if (this.sendTimeType === '定时发送' && this.startSendTime === '') {
        this.$message.warning('请选择定时时间')
        return
      }
      if (this.receivePhoneNumbers === '') {
        this.$message.warning('请输入接收手机号')
        return
      }
      const params = {
        templateId: this.message.id, //短信模板id
        templateParams: this.templateParams, //参数内容
        sendTimeType: this.sendTimeType, //发送时间类型 立即发送、定时发送
        startSendTime: this.startSendTime, //开始发送时间
        receivePhoneNumbers: this.receivePhoneNumbers //接收手机号
      }
      if (this.id !== '') params.id = this.id
      saveGroupMessage(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.id = ''
          this.message = {}
          this.sendTimeType = {}
          this.startSendTime = ''
          this.templateParams = ['']
          this.receivePhoneNumbers = ''
          return
        }
        this.$message.error(res.data)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.content-container {
  box-sizing: border-box;
  padding-top: 20px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.options-form {
  margin-bottom: 48px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  .label {
    width: 130px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 40px;
    text-align: left;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
}

.el-icon-remove-outline,
.el-icon-circle-plus-outline {
  cursor: pointer;
  margin-left: 30px;
}
.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  span {
    display: block;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 10px;
    color: #5ad3cd;
    font-weight: bold;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  margin-right: 30px;
}
.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 40px;
  padding: 0 20px;
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.search-account,
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  .options {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    text-align: center;
    margin-bottom: 15px;
    align-items: center;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 6px;
    vertical-align: bottom;
    align-items: flex-end;
    margin-right: 20px;
    span {
      font-weight: 500;
      font-size: 20px;
    }
    .icon {
      font-size: 14px;
      margin-right: 2px;
    }
    .num {
      font-size: 16px;
    }
  }
  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}
</style>
<style>
.el-empty__description {
  text-align: center;
}
.el-empty__image {
  margin: 20px auto;
}
</style>
