<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .options-form
      .label 活动名称:
      el-input(v-model="name" placeholder="请输入参数" style="width: 380px;")
    .options-form
      .label 适用业务:
      el-select(v-model="selectedFitBusiness" multiple placeholder="请选择适用业务" style="width: 380px;")
        el-option(label="会员卡" value="会员卡")
        el-option(label="知识视频" value="知识视频")
        el-option(label="质检包" value="质检包")
    .options-form
      .label 产品折扣:
      el-select(v-model="productDiscount" placeholder="请选择发送时间" style="width: 380px")
        el-option(label="不打折" value="无")
        el-option(label="95折" :value="0.95")
        el-option(label="9折" :value="0.9")
        el-option(label="85折" :value="0.85")
        el-option(label="8折" :value="0.8")
        el-option(label="75折" :value="0.75")
        el-option(label="7折" :value="0.7")
        el-option(label="65折" :value="0.65")
        el-option(label="6折" :value="0.6")
        el-option(label="55" :value="0.55")
        el-option(label="5折" :value="0.5")
        el-option(label="45折" :value="0.45")
        el-option(label="4折" :value="0.4")
        el-option(label="35折" :value="0.35")
        el-option(label="3折" :value="0.3")
        el-option(label="25折" :value="0.25")
        el-option(label="2折" :value="0.2")
        el-option(label="15折" :value="0.15")
        el-option(label="1折" :value="0.1")
    .options-form
      .label 是否送券:
      el-switch(v-model="provideCoupon" style="margin-top: 10px;")
    .options-form(v-if="provideCoupon")
      .label 送券设置:
      .project-id-el-input
        div(v-for="(item, index) in couponItems" style="margin-bottom: 48px;")
          span(style="margin-right: 30px;") 券ID:
          el-input(v-model="couponItems[index].id" placeholder="请输入参数" style="width: 100px;margin-right: 30px;")
          span(style="margin-right: 30px;") 数量:
          el-input(v-model="couponItems[index].count" placeholder="请输入参数" style="width: 100px;margin-right: 30px;")
          i.el-icon-remove-outline(@click="couponItems.splice(index, 1);" v-if="index !== 0" style="margin-right: 30px;")
          i.el-icon-circle-plus-outline(@click="couponItems.push({id: '', count: 0});")
    .options-form
      .label 活动期限:
      el-date-picker(v-model="deadline" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期")
    .options-form
      .label 活动图片:
      el-upload(:action="rule.uploadUrl"
        :headers="{token}"
        list-type="picture-card"
        :show-file-list="false"
        :disabled="rule.disable"
        :accept="rule.limitAccepts"
        :with-credentials="true"
        :before-upload="beforeAvatarUpload"
        :on-success="handleAvatarSuccess"
        :on-error="imgUploadError"
        :name="picture.name"
        v-loading="loading"
        element-loading-text="上传中")
        img(v-if="picture.url" :src="picture.url" style="width: 145px;height: 145px")
        i.el-icon-plus(v-else)
    .options-form(v-if="picture.url")
      .label 图片展示:
      el-select(v-model="picShowPositions" multiple placeholder="请选择" style="width: 380px;")
        el-option(label="首页" value="首页")
        el-option(label="公司列表页" value="公司列表页")
        el-option(label="知识列表页" value="知识列表页")
        el-option(label="质检包页" value="质检包页")
    .btns
      .action(@click="create") {{id ? '修改' : '新增'}}
  .content-container(v-if="selectType === 2")
    el-empty.el-empty(description="暂无数据" :image-size="200" v-if="records.length === 0")
    .list(v-for="item in records" v-if="records.length > 0")
      .options
        .label 活动ID:
        .params {{ item.id }}
      .options
        .label 活动名称:
        .params {{item.name}}
      .options
        .label 适用业务:
        .params {{ item.fitBusiness }}
      .options
        .label 产品折扣:
        .params {{ item.productDiscount }}
      .options(v-if="item.provideCoupon === 1")
        .label 活动送券:
        .params 
          .options(v-for="itm in item.couponItems" style="margin: 0;")
            .label(style="margin: 0; width: 270px;") {{itm.describe}}
            .params {{itm.count}} 张
      .options
        .label 活动期限:
        .params {{ item.startTime }}-{{ item.endTime }}
      .options(v-if="item.picUrl")
        .label 活动图片:
        .params 
          el-image(style="width: 200px;" :src="item.picUrl" :preview-src-list="[item.picUrl]")
      .options
        .label 图片展示:
        .params {{item.picShowPositions}}
      .options
        .label 创建人:
        .params {{item.createUserName}}
      .options
        .label 修改人:
        .params {{ item.modifyUserName }}
      .options
        .label 创建时间:
        .params {{item.createTime}}
      .options
        .label 状态:
        .params 
          el-tag(type="success" v-if="item.status === 1" style="margin-right:5px;") 已上线
          el-tag(type="success" v-if="item.status === 2" style="margin-right:5px;") 生效中
          el-tag(type="success" v-if="item.status === 3" style="margin-right:5px;") 已过期
          el-tag(type="success" v-if="item.status === 4" style="margin-right:5px;") 已下线
      .btns
        .action(@click="change(item)") 修改
        el-popconfirm(confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认删除么" @confirm="messageAction('del', item.id)" )
          .action(slot="reference") 删除
        el-popconfirm(confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认上线么" @confirm="messageAction('keep', item.id)" )
          .action(slot="reference") 上线
        el-popconfirm(confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认下线么" @confirm="messageAction('stop', item.id)" )
          .action(slot="reference") 下线
    el-pagination(
      v-if="records.length !== 0"
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 3")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        div(v-for="item in isTips")
          p {{item.question}}
          span {{item.answer}}
</template>
<script>
import {qaList, activityList, removeActivity, stopActivity, startActivity, saveActivity} from '../../api/orderAccount'

export default {
  data() {
    return {
      tabBarList: [
        {
          name: '新增/修改活动',
          value: 1
        },
        {
          name: '活动列表',
          value: 2
        },
        {
          name: '什么是“活动”？',
          value: 3
        }
      ],
      deadline: [],
      selectType: 1,
      productDiscount: '',
      provideCoupon: false,
      couponItems: [{id: '', count: 0}],
      picShowPositions: '',
      selectedFitBusiness: [],
      picUrl: '',
      token: '',
      name: '',
      id: '',
      isTips: [],
      records: [],
      page: 1,
      pageSize: 10,
      total: 0,
      rule: {
        uploadUrl: 'https://www.quanqingkeji.com/api/file/upload',
        limitSize: 10, // MB
        limitAccepts: '.jpg,.png,.JPG,.PNG,.jpeg',
        // limitTip: '仅能上传1张图片，不超过10M，格式：png、jpg',
        acceptsErrorMsg: '上传图片只能是png,jpg格式',
        disable: false
      },
      picture: {
        url: null,
        fid: null,
        name: 'picFile',
        smallUrl: null,
        size: 0
      },
      loading: false
    }
  },
  watch: {
    selectType() {
      if (this.selectType === 2) {
        this.currentChange(1)
      }
    }
  },
  props: {},
  mounted() {
    this.token = localStorage.getItem('TOKEN')

    qaList({position: '活动-什么是活动'}).then(res => {
      this.isTips = res.data
    })
    this.search()
  },
  methods: {
    change(info) {
      this.id = info.id
      this.selectType = 1
      this.name = info.name
      this.productDiscount = info.productDiscount
      this.selectedFitBusiness = info.fitBusiness.split('、')
      this.provideCoupon = info.provideCoupon === 1 ? true : false
      this.couponItems = info.couponItems
      this.deadline = [info.startTime, info.endTime]
      this.picture = {
        url: info.picUrl,
        fid: null,
        name: 'picFile',
        smallUrl: null,
        size: 0
      }
      this.picShowPositions = info.picShowPositions.split('、')
    },
    messageAction(type, id) {
      const fun = type === 'del' ? removeActivity : type === 'stop' ? stopActivity : startActivity
      if (type === 'del') {
        this.$confirm('确认删除吗？')
          .then(() => {
            fun({id}).then(res => {
              if (res.code === 200) {
                this.$message.success('操作成功')
                this.currentChange(1)
                return
              }
              this.$message.error(res.data)
            })
          })
          .catch(_ => {})
        return
      }
      fun({id}).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    create() {
      if (!this.name) {
        this.$message.warning('活动名称，最长50字。')
        return
      }
      if (this.name.length > 50) {
        this.$message.warning('请输入活动名称')
        return
      }
      
      if (this.productDiscount === '') {
        this.$message.warning('请选择折扣')
        return
      }
      if (this.selectedFitBusiness.length === 0) {
        this.$message.warning('请选择适用业务')
        return
      }
      if (this.provideCoupon && this.couponItems[0].id === '') {
        this.$message.warning('请添加送券设置')
        return
      }
      if (!this.deadline) {
        this.$message.warning('请选择活动期限')
        return
      }
      if (!this.deadline) {
        this.$message.warning('请选择活动期限')
        return
      }
      const params = {
        name: this.name,
        productDiscount: this.productDiscount,
        selectedFitBusinessMember: this.selectedFitBusiness.includes('会员卡') ? 1 : 0,
        selectedFitBusinessCourse: this.selectedFitBusiness.includes('知识视频') ? 1 : 0,
        selectedFitBusinessStandard: this.selectedFitBusiness.includes('质检包') ? 1 : 0,
        startTime: this.deadline[0],
        couponItems: this.provideCoupon ? this.couponItems : [],
        provideCoupon: this.provideCoupon ? 1 : 0,
        endTime: this.deadline[1],
        picUrl: this.picture.url,
        picShowPositions: this.picShowPositions
      }
      if (this.id !== '') params.id = this.id
      saveActivity(params).then(res => {
        if (res.code === 200) {
          this.selectType = 2
          this.$message.success('操作成功')
          this.name = ''
          this.id = ''
          this.productDiscount = ''
          this.selectedFitBusiness = []
          this.provideCoupon = false
          this.couponItems = [{id: '', count: 0}]
          this.deadline = []
          this.picture = {
            url: null,
            fid: null,
            name: 'picFile',
            smallUrl: null,
            size: 0
          }
          this.picShowPositions = []
          return
        }
        this.$message.error(res.msg)
      })
    },
    search() {
      activityList({
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.total = res.data.total
        this.records = res.data.records
      })
    },
    currentChange(val) {
      this.page = val
      this.search()
    },
    beforeAvatarUpload(file) {
      let extName = file.name.substring(file.name.lastIndexOf('.'))
      if (this.rule.limitAccepts.split(',').findIndex(e => e === extName) < 0) {
        this.$message.warning(this.rule.acceptsErrorMsg)
        return false
      }
      // 图片大小检查
      if (file.size > 1024 * 1024 * 10) {
        this.$message.warning('上传图片大小不能超过' + this.rule.limitSize + 'MB')
        return false
      }
      this.loading = true
      return true
    },
    handleAvatarSuccess(res, file) {
      this.loading = false
      this.picture.url = res?.data || ''
      this.picture.smallUrl = res.smallUrl
      this.picture.name = file.name
      this.picture.size = file.size
    },
    imgUploadError(err, file, fileList) {
      this.$message.error('上传图片失败!')
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.content-container {
  box-sizing: border-box;
  padding-top: 20px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.list {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  .options {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    text-align: center;
    margin-bottom: 15px;
    align-items: center;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 6px;
    vertical-align: bottom;
    align-items: flex-end;
    margin-right: 20px;
    span {
      font-weight: 500;
      font-size: 20px;
    }
    .icon {
      font-size: 14px;
      margin-right: 2px;
    }
    .num {
      font-size: 16px;
    }
  }
  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 40px;
  padding: 0 20px;
}
.el-empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.options-form {
  margin-bottom: 48px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  .label {
    width: 130px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 40px;
    text-align: left;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
}
.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  span {
    display: block;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 10px;
    color: #5ad3cd;
    font-weight: bold;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  margin-right: 30px;
}
</style>
<style>
.el-empty__description {
  text-align: center;
}
.el-empty__image {
  margin: 20px auto;
}
</style>
