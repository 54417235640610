<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .options
      .label 产品类型:
      el-select(v-model="businessType" placeholder="请选择产品类型" style="width: 380px")
        el-option(key="知识视频" label="知识视频" value="知识视频")
        el-option(key="会员" label="会员" value="会员")
    .options(v-if="businessType === '会员'")
      .label 会员卡期限:
      el-select(v-model="memberProductIds" placeholder="请选择会员卡期限" style="width: 380px")
        el-option(v-for="(item) in productIdList" :key="item.productId" :label="item.productName" :value="item.productId")
    .options(v-if="businessType === '知识视频'" style="margin-bottom: 0;")
      .label 产品 ID:
      .project-id-el-input
        div(v-for="(item, index) in productIds" style="margin-bottom: 48px;")
          el-input(v-model="productIds[index].id" placeholder="请输入产品 ID" style="width: 150px;" @change="(value) => {searchProject(value, index)}")
          i.el-icon-remove-outline(@click="productIds.splice(index, 1);" v-if="index !== 0")
          i.el-icon-circle-plus-outline(@click="productIds.push({});")
          .price(:style="{'margin-left': `${index === 0 ? '76px' : '30px'}`}" v-if="item.price") 价格：￥{{item.price}}
          .active-price(v-if="item.activePrice && (item.promotePrice >= item.activePrice || item.promotePrice === null)") 活动价：￥{{item.activePrice}}
          .active-price(v-if="item.promotePrice && (item.promotePrice < item.activePrice || item.activePrice === null)") 推广价：￥{{item.promotePrice}}
    .options
      .label 客户 ID:
      .project-id-el-input
        el-input(type="textarea" :autosize="{ minRows: 4, maxRows: 4}" style="width: 380px;" placeholder="请输入内容" v-model="customerIds")
    .options(v-if="allPrice")
      .label 总金额:
      .active-price ¥{{allPrice.toFixed(2)}}
    .options(v-if="brokeragePrice")
      .label 预期佣金:
      .active-price ¥{{brokeragePrice.toFixed(2)}}
    .btns
      .bargainirg(@click="create")
        span 创建
    .notice(v-if="tips")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        span(v-for="item in tips") · {{item}}
  .content-container(v-if="selectType === 2")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        span · 代销时，除了手动创建订单，也可以在各个产品页面直接代客户购买，也同样能帮客户生成订单。
        span · 如果代买，只要在各产品页直接点购买按钮到支付页，在支付页输入对方的注册帐号（即注册手机号），支付后即可为对方生成订单。如果支付页不输入对方帐号，则只是为自己买。
        span(style="margin-bottom: 80px;align-items: flex-end;") · 代买支付页，支持活动、砍价、拼团、用券、余额等，但需要您支付的金额，和您收客户的金额，要
          span(style="color: red;font-size: 20px;margin: 0 4px;display: inline-block;") 保持一致
          | ，否则如果您支付的是优惠价，收客户的是原价，那客户登录后看到的订单价比自己付给您的款少，可能引起不必要的质疑或纠纷，您也可能因此失去代买资格。
        span · 另外，在代买前，需要先激活自己的代买权限，否则在支付页是看不到代买项的。
        .desc
          span(style="display: inline-block;margin-right: 30px") 代买权限
          el-switch(v-model="enabled" @change="changeStatus")
          p(:style="{color: enabled ? '' : '#303133', 'font-size': '14px', 'font-weight': '500', 'display': 'inline-block', 'margin-left': '20px'}") {{enabled ? '已激活' : '已关闭'}}
        .btns
          .bargainirg(@click="$router.push('/techVideo')")
            span 去知识视频页
          .bargainirg(@click="$router.push('/quanqingStandard')")
            span 去质检包页
          .bargainirg(@click="$router.push('/my')")
            span 去个人中心（会员）页
  .content-container(v-if="selectType === 3")
    .search-tab(v-if="isOperator")
      .search-code
        span(style="width: 100px;") 搜索：
        el-select(v-model="queryType" placeholder="请选择搜索类型" style="margin-right: 20px;width: 350px;")
          el-option(label="代销单号" :value="1")
          el-option(label="代销人id" :value="2")
        el-input(v-model="queryContent" placeholder="请输入内容")
        img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
    .common
      .options
        .label {{total}}
        .name 代销单数
      .options
        .label
          span.icon  ¥
          span {{orderInfo.totalPrice}}
        .name 代销总金额
      .options
        .label
          span.icon  ¥
          span {{orderInfo.anticipativeCommission}}
        .name 预期佣金总额
      .options
        .label
          span.icon  ¥
          span {{orderInfo.effectiveCommission}}
        .name 已生效佣金
          el-tooltip.item(effect="dark" content="订单超过可退款期限，用户没有退款且没有申请中的退款时，佣金即生效。具体可到个人中心页的我的收入栏查阅" placement="bottom")
            i.el-icon-question
        

    .list(v-for="item in orderInfo.items")
      .options
        .label 代销单号:
        .params {{ item.orderId }}
      .options
        .label 产品类型:
        .params {{item.businessType}}
      .options
        .label 产品 ID:
        .params
          p(style="display: block;" v-for="itm in item.productList") {{ itm.productId }}, 
            span(:style="{'text-decoration': itm.activityProductPrice ? 'line-through' : ''}") {{itm.activityProductPrice ? `&nbsp;&nbsp;&nbsp;&nbsp;¥ ${itm.originalProductPrice},` : ''}}
            | {{itm.activityProductPrice ? `&nbsp;&nbsp;&nbsp;&nbsp;¥ ${itm.activityProductPrice}` : ''}}
      .options
        .label 产品名称:
        .params {{ item.productNames.split('、').length > 1 ? `${item.productNames.split('、')[0]}...等` : item.productNames}}
      .options
        .label 客户ID:
        .params {{ item.customerIds }}
      .options
        .label 总金额:
        .params ¥{{ item.totalPrice }}
      .options
        .label 预期佣金:
        .params ¥ {{ item.anticipativeCommission }}
      .options
        .label 已生效佣金:
        .params ¥ {{ item.effectiveCommission }}
      .options
        .label 生成方式:
        .params {{item.createWay === 1 ? '手动建单' : '直接代买'}}
      .options
        .label 创建时间:
        .params {{ item.createTime }}
      .options
        .label 支付状态:
        .params 
          el-tag(type="success" style="margin-right:5px;") {{item.payStatus === 0 ? '待支付' : '已支付'}}
      .options
        .label 代销人ID:
        .params {{ item.agentSaleUserId }}
      .btns
        .action(@click="handlePay(item)" v-if="item.payFlag") 支付
        .action(@click="agentSaleOrderId(item)" v-if="item.payStatus === 1") 申请退款
        .action(@click="checkOrder(item)" v-if="item.payStatus === 1") 查看生成的订单
    el-pagination(
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      v-if="orderInfo.items.length > 0"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 4")
    .notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        div(v-for="item in isTips")
          p {{item.question}}
          span {{item.answer}}
  el-dialog(:visible.sync="dialogVisible")
    .dialog-content
      .dialog-left
        .left-menu(v-for="menu in menuList" :key="menu.key" :class="{'left-menu-active':activeMenu === menu.key }" @click="handleCheckMenu(menu)") 
          img(:src="menu.icon")
          span {{menu.name}}
      .dialog-right
        div.pay-container(v-if="['weixin'].includes(activeMenu)")
          img(:src="paySrc" style="width: 200px; margin: 0px 20px 0 0;display: block;border: 0;" )
          span(style="font-size: 26px;font-weight: 500;color: #333333;") {{activeMenu === 'weixin' ? '微信扫码,': '支付宝扫码,'}} 支付&nbsp;
            span(class="pay-number" style="color: #ff0000; font-size: 38px;") {{totalPrice}} &nbsp;
            span 元
        div.pay-container(v-if="['zhifubao'].includes(activeMenu)")
          span(style="font-size: 26px;font-weight: 500;color: #333333;margin-top:100px;") 支付宝扫码, 支付&nbsp;
            span(class="pay-number" style="color: #ff0000; font-size: 38px;") {{totalPrice}} &nbsp;
            span 元
          el-button(@click="pay(1)" type="primary" style="display: block" v-if="activeMenu === 'zhifubao'") 立即支付
        div.pay-container(v-if="['balance'].includes(activeMenu)")
          div(style="font-size: 20px") 使用余额支付 &nbsp;
            span(class="pay-number") {{totalPrice}} &nbsp;
            span(style="font-size: 20px") 元
          el-select(v-model="account" placeholder="请选择账户" value-key="id")
            el-option(
              v-for="item in accountList"
              :key="item.id" :label="`${item.name}(¥ ${item.balance})`" :value="item")
          el-input(v-model="accountPassword" placeholder="请输入支付密码" type="password")
          el-button(@click="pay('balance')" type="primary" style="display: block") 立即支付
  .pay-html(v-html="payHtml")
</template>
<script>
import {
  memberOptions,
  productPrice,
  createOrder,
  getAgentSaleAuth,
  getTips,
  updateAgentSaleAuth,
  qaList,
  queryAgentSaleOrder,
  checkOrderPay,
  agentSaleRefund
} from '../../api/orderAccount'
import {orderPay} from '@/api/subject'
import {checkAuth} from '@/api/product'
import { orderAccountList} from '@/api/subject'
import md5 from 'blueimp-md5'

export default {
  data() {
    return {
      payScanCode: '',
      account: {},
      accountList: [],
      payHtml: '',
      paySrc: '',
      payOrderId: '',
      accountPassword: '',
      totalPrice: '',
      menuList: [
        {
          key: 'weixin',
          name: '微信',
          icon: require('../../assets/weixin.png'),
        },
        {
          key: 'zhifubao',
          icon: require('../../assets/zhifubao.png'),
          name: '支付宝',
        },
        {
          key: 'balance',
          icon: require('../../assets/现金余额 - 副本 1@1x.png'),
          name: '余额',
        }
      ],
      activeMenu: 'weixin',
      dialogVisible: false,
      tabBarList: [
        {
          name: '手动建单',
          value: 1
        },
        {
          name: '直接代买',
          value: 2
        },
        {
          name: '我的代销单',
          value: 3
        },
        {
          name: '什么是“代销”',
          value: 4
        }
      ],
      allPrice: '',
      brokeragePrice: '',
      memberProductIds: '',
      productIds: [{id: ''}],
      businessType: '',
      selectType: 1,
      payTimer: null,
      customerIds: '',
      productIdList: [],
      tips: [],
      enabled: '',
      isTips: [],
      queryType: '',
      queryContent: '',
      isOperator: false,
      queryTypeList: [
        {
          name: '代销单号',
          value: 1
        },
        {
          name: '代销人ID',
          value: 2
        }
      ],
      total: 0,
      page: 1,
      pageSize: 10,
      tabActiveName: '',
      orderInfo: {}
    }
  },
  props: {},
  watch: {
    selectType(){
      if (this.selectType === 3) this.currentChange(1)
    },
    businessType() {
      this.memberProductIds = ''
      this.productIds = [{id: ''}]
    },
    memberProductIds() {
      if (this.customerIds) {
        let allPrice = 0
        let brokeragePrice = 0
        const index = this.productIdList.findIndex(item => item.productId === this.memberProductIds)
        if (index === -1) return
        allPrice =
          allPrice +
          this.getMinPositiveNumber(
            Number(this.productIdList[index].price),
            Number(this.productIdList[index].activePrice),
            Number(this.productIdList[index].promotePrice)
          )
        brokeragePrice = brokeragePrice + Number(this.productIdList[index].anticipativeCommission)
        this.allPrice = allPrice * this.getUserLength()
        this.brokeragePrice = brokeragePrice * this.getUserLength()
      }
    },
    productIds: {
      handler(val) {
        if (!this.customerIds) return
        if (this.businessType === '知识视频') {
          if (this.productIds[0].id !== '') {
            let allPrice = 0
            let brokeragePrice = 0
            this.productIds.forEach(item => {
              if (item.id && item.price) {
                allPrice =
                  allPrice +
                  this.getMinPositiveNumber(Number(item.price), Number(item.activePrice), Number(item.promotePrice))
                brokeragePrice = brokeragePrice + Number(item.anticipativeCommission)
              }
              
            })
            this.allPrice = allPrice * this.getUserLength()
            this.brokeragePrice = brokeragePrice * this.getUserLength()
          }
        }
        if (this.businessType === '会员') {
          if (this.memberProductIds) {
            let allPrice = 0
            let brokeragePrice = 0
            const index = this.productIdList.findIndex(item => item.productId === this.memberProductIds)
            allPrice =
              allPrice +
              this.getMinPositiveNumber(
                Number(this.productIdList[index].price),
                Number(this.productIdList[index].activePrice),
                Number(this.productIdList[index].promotePrice)
              )
            brokeragePrice = brokeragePrice + Number(this.productIdList[index].anticipativeCommission)
            this.allPrice = allPrice * this.getUserLength()
            this.brokeragePrice = brokeragePrice * this.getUserLength()
          }
        }
      },
      deep: true,
    },
    customerIds() {
      if (this.businessType === '知识视频') {
        if (this.productIds[0].id !== '') {
          let allPrice = 0
          let brokeragePrice = 0
          this.productIds.forEach(item => {
            allPrice =
              allPrice +
              this.getMinPositiveNumber(Number(item.price), Number(item.activePrice), Number(item.promotePrice))
            brokeragePrice = brokeragePrice + Number(item.anticipativeCommission)
          })
          this.allPrice = allPrice * this.getUserLength()
          this.brokeragePrice = brokeragePrice * this.getUserLength()
        }
      }
      if (this.businessType === '会员') {
        if (this.memberProductIds) {
          let allPrice = 0
          let brokeragePrice = 0
          const index = this.productIdList.findIndex(item => item.productId === this.memberProductIds)
          allPrice =
            allPrice +
            this.getMinPositiveNumber(
              Number(this.productIdList[index].price),
              Number(this.productIdList[index].activePrice),
              Number(this.productIdList[index].promotePrice)
            )
          brokeragePrice = brokeragePrice + Number(this.productIdList[index].anticipativeCommission)
          this.allPrice = allPrice * this.getUserLength()
          this.brokeragePrice = brokeragePrice * this.getUserLength()
        }
      }
    }
  },
  mounted() {
    orderAccountList().then(res => {
      this.accountList = res.data
      console.log('%c [ this.accountList ]-393-「saleByProxy」', 'font-size:px;', this.accountList)
    })
    memberOptions().then(res => {
      this.productIdList = res.data
    })
    getTips({position: '代销-手动建单'}).then(res => {
      this.tips = res.code === 200 ? res.data : []
    })
    qaList({position: '代销-什么是代销'}).then(res => {
      this.isTips = res.data
    })
    getAgentSaleAuth().then(res => {
      this.enabled = res.data.enabled === 1 ? true : false
    })
    this.search()
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
  },
  methods: {
    getPayStatus() {
      if (!this.payTimer)
        this.payTimer = setInterval(() => {
          checkOrderPay({orderCode: this.payOrderId}).then(res => {
            const {code, data, msg} = res
            console.log('%c [ res ]-382-「pay」', 'font-size:px;', res)
            if (code === 200 && data) {
              if (res.data.paySuccess) {
                this.$dialog.toast('支付成功')
                this.payTimer && clearInterval(this.payTimer)
                this.payTimer = null
                setTimeout(() => {
                  this.currentChange(1)
                  this.dialogVisible = false
                }, 200)
              }
            } else {
              this.$dialog.toast(msg)
            }
          })
        }, 1000)
    },
    searchBtnClick() {
      if (this.queryType === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.queryContent === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.currentChange(1)
    },
    getUserLength() {
      let length = 0
      const customerIds = this.customerIds.split(',')
      customerIds.forEach(item => {
        if (item) length = length + 1
      })
      return length
    },
    agentSaleRefund(info) {
      agentSaleRefund({agentSaleOrderId: info.orderId}).then(res => {
        if (res.code === 200) {
          this.$message.success('取消成功')
        }
      })
    },
    checkOrder(item) {
      this.$router.replace(`/orderList?orderId=${item.orderId}&queryType=4&orderType=3`)
    },
    search() {
      queryAgentSaleOrder({
        queryType: this.queryType,
        queryContent: this.queryContent,
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.total = res.data.total
        this.orderInfo = res.data
      })
    },
    currentChange(val) {
      this.page = val
      this.search()
    },
    searchProject(value, index) {
      productPrice({productId: value}).then(res => {
        this.$set(this.productIds[index], 'price', res.data.price)
        this.$set(this.productIds[index], 'activePrice', res.data.activePrice)
        this.$set(this.productIds[index], 'promotePrice', res.data.promotePrice)
        this.$set(this.productIds[index], 'anticipativeCommission', res.data.anticipativeCommission)
      })
    },
    changeStatus() {
      updateAgentSaleAuth({enabled: this.enabled ? 1 : 0}).then(res => {
        if (res.code === 200) {
          this.$message.success('更新成功')
        }
      })
    },
    getMinPositiveNumber(a, b, c) {
      // 过滤掉0和负数，保留大于0的数
      const positiveNumbers = [a, b, c].filter(function(num) {
        return num > 0
      })
      // 如果没有大于0的数，返回undefined
      if (positiveNumbers.length === 0) {
        return undefined
      }
      // 使用Math.min获取最小的大于0的数
      return Math.min(...positiveNumbers)
    },
    create() {
      if (this.businessType === '') {
        this.$message.warning('请选择产品类型')
        return
      }

      if (this.businessType === '会员' && !this.memberProductIds) {
        this.$message.warning('请选择会员卡期限')
        return
      }
      if (this.businessType === '知识视频' && this.productIds[0].id === '') {
        this.$message.warning('请输入产品 ID')
        return
      }
      if (this.customerIds === '') {
        this.$message.warning('请输入客户 ID')
        return
      }
      const params = {
        businessType: this.businessType, //产品类型 知识视频；会员
        productIds: [], //产品id列表 会员、知识视频等
        customerIds: this.customerIds //客户ids
      }
      if (this.businessType === '知识视频') {
        this.memberProductIds = this.productIds.map(item => item.id)
        params.productIds = this.memberProductIds
      }
      if (this.businessType === '会员') {
        params.productIds.push(this.memberProductIds)
      }
      createOrder(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.selectType = 3
          this.businessType = ''
          this.memberProductIds = ''
          this.productIds = [{id: ''}]
          this.customerIds = ''
          this.allPrice = ''
          this.brokeragePrice = ''
          this.search()
          return
        }
        this.$message.error(res.data)
      })
    },
    pay(type) {
      const params = {
        orderCode: this.payOrderId,
        alipayReturnUrl: window.location.href
      }
      let message = ''
      if (this.activeMenu !== 'balance') {
        params.payType = type
      } else {
        if (!this.accountPassword) {
          message = '请输入密码'
        }
        if (!this.account) {
          message = '请选择账户'
        }
        params.payPassword = md5(this.accountPassword)
        params.accountId = this.account && this.account.id ? this.account.id : ''
      }
      if (message) {
        this.$message.error(message)
        return
      }
      orderPay(params).then(res => {
        if (res.code === 200) {
          // 微信支付
          if (type === 2) {
            this.paySrc = res.data
            this.getPayStatus()
            return
          } else if (type === 1) {
            this.payHtml = res.data
            this.$nextTick(() => {
              document.punchout_form.submit()
            })
          } else if (item.data === '使用余额或优惠券支付成功') {
            this.dialogVisible = false
            this.$message.success('支付成功')
            return
          }
          this.account = {}
          this.accountPassword = ''
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePay(info) {
      this.dialogVisible = true
      this.payOrderId = info.orderId
      this.totalPrice = info.totalPrice
      this.pay(2)
    },
    handleCheckMenu(info) {
      if (info.key === 'weixin') {
        this.pay(2)
        this.activeMenu = info.key
      } else if (info.key === 'zhifubao') {
        // this.pay(1)
        this.activeMenu = info.key
      } else {
        this.activeMenu = info.key
      }

    }
  },
  beforeDestroy() {
    this.payTimer && clearInterval(this.payTimer)
    this.timer && clearInterval(this.timer)
    this.payTimer = null
  },
}
</script>

<style scoped lang="scss">
.pay-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
/deep/.el-dialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .dialog-content {
    display: flex;
    .el-input {
      margin: 20px 0;
      width: 250px !important;
    }
    .dialog-left {
      width: 200px;
      background-color: #f5f5f5;
      font-size: 24px;
      font-weight: 500;
    }
    .dialog-right {
      flex: 1;
      display: flex;
      padding: 40px;
      img {
        width: 100px;
        border: 1px solid grey;
        margin-right: 8px;
      }
      span {
        font-size: 20px;
        font-weight: 500;
      }
      .pay-number {
        font-size: 40px;
        font-weight: 600;
      }
    }
    .left-menu {
      height: 140px;
      line-height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // img {
      //   width: 48px;
      //   margin-right: 16px;
      // }
      img {
        width: 48px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
    .left-menu-active {
      background-color: #ffffff;
    }
  }
}
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.content-container {
  height: 100%;
  box-sizing: border-box;
  padding-top: 20px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.label {
  width: 170px;
  font-weight: 600;
  font-size: 22px;
  color: #333333;
  line-height: 40px;
  text-align: left;
}
.options {
  margin-bottom: 48px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
}
.project-id-input {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 48px;
}
.el-icon-remove-outline,
.el-icon-circle-plus-outline {
  cursor: pointer;
  margin-left: 30px;
}
.project-id-el-input {
  display: flex;
  flex-direction: column;
}
.price {
  font-weight: 600;
  margin-right: 40px;
  font-size: 22px;
  color: #acacac;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  display: inline-block;
  margin-left: 30px;
}
.active-price {
  font-weight: 600;
  font-size: 22px;
  color: #ff724c;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  display: inline-block;
  margin-right: 30px;
}
.content-container {
  .bargainirg {
    height: 56px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    text-align: center;
    cursor: pointer;
    span {
      font-weight: 600;
      font-size: 22px !important;
      color: #ffffff !important;
      text-align: center;
      margin: 0;
    }
  }

  .common {
    background-color: #f7f7f7;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
    .options {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 0;
    }
    .label {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-bottom: 6px;
      vertical-align: bottom;
      align-items: flex-end;
      span {
        font-weight: 500;
        font-size: 20px;
      }
      .icon {
        font-size: 14px;
        margin-right: 2px;
      }
      .num {
        font-size: 16px;
      }
    }
  }
  .list {
    margin-bottom: 70px;
    border-bottom: 1px solid #f7f4f8;
    .label {
      width: 160px;
      font-weight: 600;
      font-size: 22px;
      color: #acacac;
      line-height: 26px;
      text-align: left;
    }
    .options {
      margin-bottom: 12px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
    }
    .params {
      font-weight: 600;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      text-align: left;
    }
  }
  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}

.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  span {
    display: block;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 10px;
    color: #5ad3cd;
    font-weight: bold;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  margin-right: 30px;
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 40px;
  padding: 0 20px;
}
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
</style>
